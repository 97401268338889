import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "link-content-to-a-tab"
    }}>{`Link Content To a Tab`}</h1>
    <hr></hr>
    <p>{`To link Content Item to a tab within your app, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Select the Mobile App or Template you would like to link the content to.`}</li>
      <li parentName="ol">{`Select the tab from the Tab Manager list that you wish to edit.`}</li>
      <li parentName="ol">{`Select 'Edit Tab: (tab name)'. This action will open the Edit Tab page.`}</li>
      <li parentName="ol">{`Set the tab contents to 'Content Item'. From the dropdown select the Content Item you want this tab to display when selected in your app.`}</li>
      <li parentName="ol">{`Click 'Save' to save the changes.`}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      